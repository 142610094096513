$andes-theme: mercadoplay;

@use '../../../container/ErrorContainer/ErrorContainer';
@use '../../../components/SubHeader/desktop/style';
@import '~@andes/common/index';
@import '../../../styles';

.search-results-desktop {
  flex: 1;
  height: auto;

  .sub-header-desktop {
    padding-bottom: 32px;
  }

  &__content {
    margin: 0 auto pxToRem(34);
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
  }

  .mediacard__bottom-left-item {
    margin-bottom: 12px;

    .andes-badge--pill {
      border-radius: 3px;
    }
  }

  .andes-badge--xs {
    .andes-badge__content {
      padding: 6px;
    }
  }

  &__feed {
    height: 100%;
    overflow: initial;

    .infinite-scroll-component {
      margin: 0 !important;
      padding: 0 !important;
      overflow: initial !important;
    }

    .feed__item--typography {
      grid-column: 1/-1;
    }

    & .typography-wrapper--has-border-bottom {
      border-bottom: 1px solid $andes-gray-100;
      color: $andes-text-color-primary;
      margin: 0;
      padding-bottom: 16px;
      word-break: break-word;
    }
  }
  .mediacard__header {
    .andes-progress-indicator-linear {
      position: absolute;
      bottom: 0;
      margin: 0;
    }
  }
}
