@import '../../../styles/index';

$display: $desktop !default;

.parental-control-modal {
  padding: 30px 32px 32px;

  .andes-modal__header {
    padding: 0;
    margin-bottom: 12px;
  }

  .andes-modal__title {
    @if $display ==$desktop {
      font-size: 24px;
      text-align: left;
      width: 100%;
    }
  }

  .andes-modal__content {
    padding: 0;
    margin-bottom: 35px;

    p {
      margin: 0;

      strong {
        font-weight: 600;
      }

      @if $display ==$mobile {
        font-size: 16px;
        text-align: center;
      }
    }

    @if $display ==$mobile {
      margin-bottom: 24px;
    }
  }

  .andes-modal__actions {
    padding: 0;

    @if $display ==$mobile {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button.andes-button {
        margin: 0;
      }
    }
  }

  &.andes-modal--card {
    .andes-modal__actions {
      padding: 0;
    }

    .andes-modal__header {
      padding: 0;
    }

    .andes-modal__content {
      padding: 0;
    }
  }

  @if $display == $mobile {
    width: 80%;
    padding: 24px;
  }
}

div.andes-modal__overlay--card {
  align-items: center;
}
