@import '../../styles/functions';

body:has(.splash-screen) {
  overflow: hidden;
}

.splash-screen {
  position: fixed;
  z-index: 2147483641;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &__background {
    width: 100%;
    height: 100%;
  }

  &__brand {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: pxToRem(142);
    width: pxToRem(390);
    align-items: center;

    span.splash-screen__subtitle {
      padding-top: pxToRem(16);
      padding-bottom: pxToRem(99);
      color: white;
      margin: 0;
      font-size: 32px;
      line-height: 40px;
    }
  }

  &--gradient {
    background: linear-gradient(#000c, #0000001a);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    align-self: center;
  }
}

.splash-screen__logo--mlb {
  height: 77px;
  width: 300px;
}

.splash-screen__logo {
  width: 332px;
  height: 85px;
}
